import React from 'react';
import { navigate } from 'gatsby';
import languageStore from '../../store/languageStore';
import planStore from '../../store/planStore';
import * as EN from '../../i18n/messages/en-US';
import * as DE from '../../i18n/messages/de-DE';
import * as constants from '../../app.constant';
import { observer } from 'mobx-react';
import { LOCALES } from '../../i18n';
import { getModifiedPrice } from '../../service/prod-config';
import translate from '../../i18n/translate';

const Products = observer((props) => {
  const getText = (keyName) => {
    const keyArr = keyName.split('.');
    if (keyArr.length > 1) {
      if (languageStore.selectedLanguage === LOCALES.ENGLISH) {
        return EN[keyArr[0]][keyArr[1]];
      } else {
        return DE[keyArr[0]][keyArr[1]]
      }
    }
  }

  const gotoProductDetails = (item) => {
    planStore.updateProductInfo(item);
    navigate(constants.ROUTES.PRODUCT_INFO);
  }

  return (
    <>
      <div className="row">
        {planStore.displayProductList &&
          planStore.displayProductList.map(item => {
            let modifiedPrice
            if (item.plans)
              modifiedPrice = getModifiedPrice(item.plans.actualProductPrice);
            return (
              <div key={item.productId} className="col-4">
                {item.plans && <div className="product-card landing-page-product-card">
                  {item.isPopular &&  <div className="most-comprehensive">{translate("home.products.popular")}</div>}
                  <h2 className="productName" dangerouslySetInnerHTML={{ __html: getText(item.productName) }}></h2>
                  <div className="productPrice">{item.plans && item.plans.actualProductPrice && modifiedPrice}{item.plans && item.plans.actualProductPrice && item.currencySymbol}</div>
                  {item.plans && item.plans.actualProductPrice && <div className="per-month">{item.tenureToShow}</div>}
                  <div className="button-wrapper"><button disabled={!props.canCustomerProceed} type="button" className="btn btn-cyan" onClick={() => gotoProductDetails(item)}><span>{translate('home.products.cta.learnmore')}</span></button></div>
                  <ul className="coverageType">
                    {item.featureList && item.featureList.map(featureItem => (
                      <li key={featureItem}>{getText(featureItem)}</li>
                    ))}
                  </ul>
                </div>}
              </div>
            )
          })}
      </div>
    </>
  )
})

export default Products;