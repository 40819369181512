export const ROUTES = {
    HOME: '',
    DEVICE_INFO: '/device-info',
    PRODUCT_INFO: '/product-info',
    ORDER_SUMMARY: '/order-summary',
    NOT_ELIGIBLE: 'device-not-covered',
    PRIVACY: 'privacy-policy',
    TNC1YEAR: 'tnc/12mth',
    TNCMONTHLY: 'tnc/monthly',
    DEVICE_INFO_ERROR: 'device-info-error',
    UA_ERROR: 'ua-error',
    CONTACT_INFO: '/contact-info',
    PAYMENT: '/payment',
    PAYMENT_SUCCESS: '/order-success',
    PAYMENT_FAIL: '/order-failed',
    ORDER_CREATE_ERROR: '/ordercreate-error'
}

export const IMAGES = {
    LOGO_HEADER: 'assets/images/bolttech_logo_header.svg'
}

export const HEADER_REQUEST = {
    "boltCountryCode": "AT",
    "boltTenantId": "ATDREIATELNA01",
    "boltLanguageCode": "EN",
    "boltChannel": "web"
}

export const BUTTON = {
    GET_50PERCENT_OFF: "GET 50% OFF TODAY",
    LETS_GET_STARTED: "Let’s Get Started",
    GET_THIS_PLAN_NOW: "GET THIS PLAN NOW"
}

export const PARTNER_CODE = "ATDREIATELNA01";

export const DISCOUNT = {
    IS_DISCOUNT: true
}

export const PRODUCT_IDS = {
    MOBILE_COVER: 'AT_DREI_SMART_COVER_1M',
    MOBILE_COVER_PLUS: 'AT_DREI_SMART_COVER_PLUS_1M',
    MOBILE_COVER_DISPLAY: 'AT_DREI_ADLD_EW_1M'
}

export const EMAIL = {
    SUBJECT: 'Vorvertragliche Offenlegung',
    TEMPLATE_NAME: 'drei/pre-contractual-email',
    FROM: 'device-noreply@bolttech.my'
}
export const BASIS_CODE_INITIAL = 'SM-'